<div class="admin-container">
    <div class="sub-admin-left-container">
        <div class="users">
            <div class="users-buttons">
                <button [disabled]="disableButtons" (click)="save()"  [ngClass]="{'disabled-button': !showSaveButton}">{{strings["save"]}}</button>
            </div>
            <div class="users-list-container">
                <span class="users-sub-header">{{strings["users"]}}</span>

                <input class="users-search search-function" type="text" [placeholder]="strings['search']"
                    [(ngModel)]="searchStringUser" (input)="filterUserList(selectedRole)">


                <div *ngIf="!selectedRole" class="users-list-item-container">
                    <div class="users-list-item" *ngFor="let user of filteredUsers; let i = index"
                        [class.selected]="user===selectedUser" [ngClass]="{'gray-background': shouldGrayBackground(i)}"
                        (click)="selectUser(user)">
                        {{user.firstName}}
                        {{user.lastName}}
                    </div>
                </div>
                <div *ngIf="selectedRole" class="users-list-item-container">
                    <div class="users-list-item" *ngFor="let user of matchingUsers; let i = index"
                        [class.selected]="user===selectedUser" [ngClass]="{'gray-background': shouldGrayBackground(i)}"
                        (click)="selectUser(user)">
                        {{user.firstName}}
                        {{user.lastName}}
                    </div>
                </div>
                <div *ngIf="selectedRole" class="seperator-container">
                    <div class="seperator"></div>
                </div>
                <div *ngIf="selectedRole" class="users-list-item-container">
                    <div class="users-list-item" *ngFor="let user of nonMatchingUsers; let i = index"
                        [class.selected]="user===selectedUser" [ngClass]="{'gray-background': shouldGrayBackground(i)}"
                        (click)="selectUser(user)">
                        {{user.firstName}}
                        {{user.lastName}}
                    </div>
                </div>
            </div>
        </div>
        <div class="roles">
            <div class="roles-title-section">
                <span class="roles-sub-header">{{strings["roles"]}}</span>
                <div class="roles-title-section-buttons">
                    <button [disabled]="disableButtons" (click)="addRole()">{{strings["addRole"]}}</button>
                    <button [disabled]="!selectedRole" [ngClass]="{'disabled-button': !selectedRole}" (click)="editRole()">{{strings["editRole"]}}</button>
                </div>
            </div>

            <input class="roles-search search-function" type="text" [placeholder]="strings['search']"
                [(ngModel)]="searchStringRole" (input)="filterRoleList()">
            <div class="roles-container">

                <div class="column-headers">
                    <span>
                        {{strings["roleName"]}} / {{strings["enable"]}}
                    </span>
                    <span>
                        {{strings["expirationDate"]}}
                    </span>
                </div>
                <div class="column-headers-container-roles">
                    <div class="roles-list-item" *ngFor="let role of filteredRoles; let i = index"
                        [ngClass]="{'gray-background': shouldGrayBackground(i), 'selected': role===selectedRole,'selected-role': role===selectedRole && this.editIndex !=null && this.enableEditing===true}"
                        (click)="selectRole(role)">
                        <input class="name"
                            [ngClass]="{'read-input-only': editIndex !== role.id,'': editIndex === role.id}"
                            [(ngModel)]="role.name" (click)="toggleEditing(role)">
                        <button class="update-button" *ngIf="editIndex === role.id && this.enableEditing==true"
                            (click)="updateEdit()">Update</button>
                        <input
                            [matTooltip]="role.id==='_Owner'&& oneOwner===true && role.enable===true? strings['atLeastOneOwner'] : null"
                            class="center-item checkbox" type="checkbox" id="enable"
                            [disabled]="selectedUser == null || (role.id==='_Owner'&& oneOwner===true && role.enable===true)"
                            [(ngModel)]="role.enable" (click)="toggleUserRole(selectedUser, role)">
                        <input class="role-expiration-date" [matTooltip]="strings['showDatePicker']"
                            [ngClass]="{'selected-role-expiration-date': role===selectedRole && this.editIndex !=null && this.enableEditing===true}"
                            type="date" id="expirationDate" [ngModel]="role.expirationDate| date:'yyyy-MM-dd'"
                            (ngModelChange)="role.expirationDate=$event"
                            (change)="toggleUserExpirationDate(selectedUser, role)">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-admin-right-container">
        <div class="right-container-header">
            <span class="permissions-button" [ngClass]="{'highlight-button': selectedTab == 'Permissions'}"
                (click)="setTab('Permissions')">
                {{strings["permissions"]}}
            </span>
            <span class="file-groups-button" [ngClass]="{'highlight-button': selectedTab == 'FileGroups'}"
                (click)="setTab('FileGroups')">
                {{strings["fileGroup"]}}
            </span>
        </div>
        <div class="select-role-text" *ngIf="!selectedRole">{{strings["selectARole"]}}</div>
        <!-- Permissions -->
        <div class="permissions" *ngIf="selectedTab == 'Permissions' && selectedRole != null">
            <!-- Security Admin Permissions -->
            <div class="permissions-header-container">
                <div class="permissions-admin-header-container">
                    <span class="center-item permission-name">{{strings["securityAdmin"]}}</span>
                </div>
            </div>
            <div class="permissions-header-container">
                <div class="permissions-admin-header-container">
                    <input class="center-item checkbox" type="checkbox"
                        [checked]="selectedRole.permissions.securityAdmin" (click)="toggleSecurityAdmin()"
                        [disabled]="selectedRole.id=='_Owner'">
                </div>
            </div>
            <!-- <div class="permissions-checkbox-container">
                <span class="left-align-item left-sub-headers">Security</span>
                <input class="center-item checkbox" type="checkbox" [checked]="selectedRole.permissions.securityAdmin"
                    (click)="toggleSecurityAdmin()" [disabled]="selectedRole.id=='_Owner'">
                <div class="center-item spacers"></div>
                <div class="center-item spacers"></div>
            </div> -->
            <div class="seperator-container-permissions">
                <div class="seperator"></div>
            </div>
            <!-- Table Permissions -->
            <span class="sub-headers">{{strings["tables"]}}</span>
            <div class="permissions-header-container-tables">
                <div class="center-item spacers"></div>
                <span class="permission-name center-item">{{strings["admin"]}}</span>
                <span class="permission-name center-item">{{strings["edit"]}}</span>
                <span class="permission-name center-item">{{strings["read"]}}</span>
            </div>
            <div class="permissions-checkbox-container-tables-full-access">
                <div class="name left-align-item permission-name-full-access">{{strings["fullAccess"]}}</div>
                <input class="center-item checkbox" type="checkbox" [checked]="tableAdminEnabled()"
                    (click)="toggleTableAdmin()"
                    [disabled]="selectedRole.id=='_Owner' || selectedRole.permissions.securityAdmin">
                <div class="center-item spacers"></div>
                <div class="center-item spacers"></div>
            </div>
            <div class="permissions-checkbox-container-tables" *ngFor="let table of tables; let i = index"
                [ngClass]="{'gray-background': shouldGrayBackground(i)}">
                <div class="name left-align-item">{{table.actualName}}</div>
                <input class="center-item checkbox" type="checkbox"
                    [checked]="tablePermissionEnabled(table.name, 'Admin')"
                    (click)="toggleTablePermission(table.name, 'Admin')"
                    [disabled]="selectedRole.id=='_Owner' || tableAdminEnabled()">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="tablePermissionEnabled(table.name, 'Write') || tablePermissionEnabled(table.name, 'Admin')"
                    (click)="toggleTablePermission(table.name, 'Write')"
                    [disabled]="selectedRole.id=='_Owner'|| tablePermissionEnabled(table.name, 'Admin')">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="tablePermissionEnabled(table.name, 'Read') || tablePermissionEnabled(table.name, 'Admin')"
                    (click)="toggleTablePermission(table.name, 'Read')"
                    [disabled]="selectedRole.id=='_Owner'|| tablePermissionEnabled(table.name, 'Admin')">
            </div>
            <div class="seperator-container-permissions">
                <div class="seperator"></div>
            </div>
            <span class="sub-headers">{{strings["integrations"]}}</span>
            <!-- Integration Permissions -->
            <div class="permissions-header-container-integration">
                <div class="center-item spacers"></div>
                <span class="center-item permission-name">{{strings["admin"]}}</span>
                <span class="center-item permission-name">{{strings["execute"]}}</span>
                <span class="center-item permission-name">{{strings["processLogs"]}}</span>
                <span class="center-item permission-name">{{strings["warningAndErrorLogs"]}}</span>
                <span class="center-item permission-name">{{strings["dataValidation"]}}</span>
                <span class="center-item permission-name">{{strings["output"]}}</span>

            </div>
            <div class="permissions-checkbox-container-full-access">
                <div class="name left-align-item permission-name-full-access">{{strings["fullAccess"]}}</div>
                <input class="left-align-item checkbox" type="checkbox" [checked]="integrationAdminEnabled()"
                    (click)="toggleIntegrationAdmin()"
                    [disabled]="selectedRole.id=='_Owner'|| selectedRole.permissions.securityAdmin">
                <div class="left-align-item spacers"></div>
                <div class="left-align-item spacers"></div>
                <div class="left-align-item spacers"></div>
            </div>

            <div class="permissions-checkbox-container" *ngFor="let integration of integrations; let i = index"
                [ngClass]="{'gray-background': shouldGrayBackground(i)}">
                <div class="name left-align-item">{{integration.name}}</div>

                <input class="center-item checkbox" type="checkbox"
                    [checked]="integrationPermissionEnabled(integration.name, 'Admin')"
                    (click)="toggleIntegrationPermission(integration.name, 'Admin')"
                    [disabled]="selectedRole.id=='_Owner' || integrationAdminEnabled()">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="integrationPermissionEnabled(integration.name, 'Process') || integrationPermissionEnabled(integration.name, 'Admin')"
                    (click)="toggleIntegrationPermission(integration.name, 'Process')"
                    [disabled]="selectedRole.id=='_Owner'|| integrationPermissionEnabled(integration.name, 'Admin')">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="integrationPermissionEnabled(integration.name, 'RunLog') || integrationPermissionEnabled(integration.name, 'Admin')"
                    (click)="toggleIntegrationPermission(integration.name, 'RunLog')"
                    [disabled]="selectedRole.id=='_Owner'|| integrationPermissionEnabled(integration.name, 'Admin')">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="integrationPermissionEnabled(integration.name, 'ErrorLog') || integrationPermissionEnabled(integration.name, 'Admin')"
                    (click)="toggleIntegrationPermission(integration.name, 'ErrorLog')"
                    [disabled]="selectedRole.id=='_Owner'|| integrationPermissionEnabled(integration.name, 'Admin')">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="integrationPermissionEnabled(integration.name, 'DataValidation') || integrationPermissionEnabled(integration.name, 'Admin')"
                    (click)="toggleIntegrationPermission(integration.name, 'DataValidation')"
                    [disabled]="selectedRole.id=='_Owner'|| integrationPermissionEnabled(integration.name, 'Admin')">
                <input class="center-item checkbox" type="checkbox"
                    [checked]="integrationPermissionEnabled(integration.name, 'DataFiles') || integrationPermissionEnabled(integration.name, 'Admin')"
                    (click)="toggleIntegrationPermission(integration.name, 'DataFiles')"
                    [disabled]="selectedRole.id=='_Owner' || integrationPermissionEnabled(integration.name, 'Admin')">
            </div>
            <div *ngIf="notProd" class="debug-checkbox-container">
                <div *ngIf="!localHost">
                    Localhost Disabled
                    <img src="assets/images/icons/info-icon.svg" alt="Info" class="info-icon"
                        title="This permission is intended ONLY for DIS consultants when debugging.  Enabling this permission can alter expected results when running solutions">
                </div>
                <div *ngIf="localHost">
                    Localhost Enabled
                    <img src="assets/images/icons/info-icon.svg" alt="Info" class="info-icon"
                        title="This permission is intended ONLY for DIS consultants when debugging.  Enabling this permission can alter expected results when running solutions">
                </div>
                <input class="center-item checkbox" type="checkbox" [(ngModel)]="localHost" (click)="toggleLocalhost()"
                    [checked]="selectedRole.permissions.disLocalhostPermission">
            </div>
        </div>
        <!-- File Groups -->
        <div class="file-groups" *ngIf="selectedTab == 'FileGroups' && selectedRole != null">

            <!-- Assigned File Groups -->
            <div class="file-groups-container">
                <p class="file-groups-header">{{strings["assigned"]}}</p>
                <div class="file-groups-container-inner">
                    <input class="file-search search-function" type="text" [placeholder]="strings['search']"
                        [(ngModel)]="searchStringAssign" (input)="filterAssignList()">
                    <span *ngFor="let fileGroup of filteredAssignedFiles; let i =index"
                        (click)="toggleFileGroupSelection(fileGroup)"
                        [ngClass]="{'gray-background': shouldGrayBackground(i), 'selected': fileGroup.isSelected}">
                        <div class="file-name">
                            {{fileGroup.name}}
                        </div>
                    </span>
                </div>
            </div>
            <!-- Assignment Buttons -->
            <div class="arrow-container">
                <button (click)="RemoveSelectedFileGroupsFromRole()" [disabled]="selectedRole.id=='_Owner'">
                    <img src="../../assets/images/icons/FillchevronRight.svg">
                </button>
                <button (click)="AssignSelectedFileGroupsToRole()" [disabled]="selectedRole.id=='_Owner'">
                    <img src="../../assets/images/icons/FillchevronLeft.svg">
                </button>
            </div>
            <!-- Available File Groups -->
            <div class="file-groups-container">
                <p class="file-groups-header">{{strings["available"]}}</p>
                <div class="file-groups-container-inner">
                    <input class="file-search search-function" type="text" [placeholder]="strings['search']"
                        [(ngModel)]="searchStringAvaliable" (input)="filterAvailableList()">
                    <span *ngFor="let fileGroup of filteredAvailableFiles; let i =index"
                        (click)="toggleFileGroupSelection(fileGroup)"
                        [ngClass]="{'gray-background': shouldGrayBackground(i), 'selected': fileGroup.isSelected}">
                        <div class="file-name">
                            {{fileGroup.name}}
                        </div>
                    </span>
                    <!-- Dropping these components temporarily for demo stuff -->
                    <!-- <div class="bottom-edit-buttons-container">
                        <div class="bottom-edit-buttons">
                            <button (click)="addFile()">add</button>
                            <button (click)="editFile()">edit</button>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
import { Component, OnInit } from "@angular/core";
import { Role } from "src/app/models/admin/role";
import { UserV2 } from "src/app/models/admin/user-v2";
import { AdminService } from "src/app/services/admin.service";
import { FileGroup } from "src/app/models/admin/file-group";
import { TableData } from "src/app/models/table-data";
import { TablePermission } from "src/app/models/admin/permissions/table-permission";
import { IntegrationDetails } from "src/app/models/integration-details";
import { FileGroupPermission } from "src/app/models/admin/permissions/file-group-permission";
import { AuthService } from "src/app/services/auth-service.service";
import { ToastService } from 'src/app/services/toast.service';
import { StringLocalizerService } from "src/app/services/string-localizer.service";
import { ConfigService } from "src/app/services/config.service";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent implements OnInit {
  // Public/Private members ===========================================================================================
  public selectedTab: string = "Permissions"  // Accepted values are "Permissions" and "FileGroups"

  public fileGroups: FileGroup[] = [];
  public roles: Role[] = [];
  public tables: TablePermission[];
  public users: UserV2[];
  public integrations: IntegrationDetails[];

  public selectedUser: UserV2;
  public selectedRole: Role;
  public selectedFile: File[];

  public availableFileGroups: FileGroup[] = [];
  public assignedFileGroups: FileGroup[] = [];

  public updatedUsers: UserV2[] = [];
  public nonMatchingUsers: UserV2[] = [];
  public matchingUsers: UserV2[] = [];
  public updatedRoles: Role[] = [];

  public filteredAssignedFiles: FileGroup[] = [];
  public filteredAvailableFiles: FileGroup[] = [];
  public filteredRoles: Role[] = [];
  public filteredUsers: UserV2[] = [];

  public searchStringRole: string = '';
  public searchStringAssign: string = '';
  public searchStringAvaliable: string = '';
  public searchStringUser: string = '';

  editIndex: any | null = null;
  editRoleNameEnable: boolean = false
  enableEditing: boolean = false;
  private userId: string;
  public oneOwner: boolean = false;
  public disableButtons: boolean = true;
  public strings: {} = {};
  public showSaveButton: boolean = false;
  public localHost: boolean = false;
  public notProd: boolean = false; 

  // Constructor ===========================================================================================
  constructor(private authService: AuthService, private adminService: AdminService, private toastService: ToastService, private stringLocalizerService: StringLocalizerService, private configService: ConfigService) {
    this.authService.$userClaims.subscribe(claims => {
      if (claims != null && claims.length > 0) {
        let userId = claims.find(claim => claim.type == "df.userid").value;
        this.userId = userId ? userId : "UNKNOWN_USER_ID"
      }
    });
    this.adminService.$disableAdminButtons.subscribe(disableButtons => {
      this.disableButtons = disableButtons;
    });
    this.stringLocalizerService.strings.subscribe(s => {
      if (s) {
        this.strings = this.stringLocalizerService.getSection("admin");
      }
    });
    if (this.configService.getEnvironment() != "PROD") {
      this.notProd = true;
    }
  }


  // Lifecycle Hooks ===========================================================================================
  async ngOnInit(): Promise<void> {
    await Promise.all(
      [this.adminService.loadUsers(),
      this.adminService.loadRoles(),
      this.adminService.loadTableData(),
      this.adminService.loadFileGroups(),
      this.adminService.loadIntegrations()]);

    this.users = this.adminService.users;
    this.filteredUsers = [...this.users];
    this.roles = this.adminService.roles;
    this.filteredRoles = [...this.roles];
    this.initialRoles = [...this.roles]
    this.tables = this.adminService.tables.map(t => this.toTablePermission(t));
    this.fileGroups = this.adminService.fileGroups;
    this.integrations = this.adminService.integrations;
  }

  //State management ===========================================================================================
  selectUser(user) {
    this.selectedUser = user;
    this.calculateEnabledRoles();
    this.checkForOwners();
  }

  async selectRole(role) {
    this.selectedRole = role;
    this.calculateFileGroupAssignments();
    this.filteredAssignedFiles = [...this.assignedFileGroups]
    this.filteredAvailableFiles = [...this.availableFileGroups]
    this.localHost = this.selectedRole.permissions.disLocalhostPermission;
    this.filterUserListSeletedRole(role);
  }

  setTab(selectedTab: string) {
    this.selectedTab = selectedTab;
  }

  filterUserListSeletedRole(role) {
    this.matchingUsers = this.users.filter(user => user.roles.some(userRole => userRole.id === role.id));
    this.nonMatchingUsers = this.users.filter(user => !user.roles.some(userRole => userRole.id === role.id));
  }

  //Enable + Disable user roles ===========================================================================================
  toggleUserRole(user: UserV2, role: Role) {
    let userRole = user.roles.find(userRole => userRole.id === role.id)
    if (userRole) {
      user.roles.splice(user.roles.indexOf(userRole), 1)
    } else {
      user.roles.push(new Role(role.id, Date(), role.expirationDate, this.userId));
    }
    this.addUserToUpdatedCollection(user);
    this.oneOwner = this.isOnlyOneOwner();
  }
  toggleUserExpirationDate(user: UserV2, role: Role) {
    var userRole = user.roles.find(userRole => userRole.id === role.id)
    userRole.expirationDate = role.expirationDate;
    userRole.expirationDate.toString()
    this.addUserToUpdatedCollection(user);
  }

  calculateFileGroupAssignments() {
    this.assignedFileGroups = [];
    this.availableFileGroups = [];
    this.fileGroups.forEach(f => {
      if (this.selectedRole.permissions.fileGroups.groups.some(roleFileGroup => roleFileGroup.name === f.id)) {
        this.assignedFileGroups.push(f);
      } else {
        this.availableFileGroups.push(f);
      }
    });
  }

  // Filter Users
  filterUserList(selectedRole = null) {
    const searchLower = this.searchStringUser.trim().toLocaleLowerCase().split(' ');
    if (selectedRole) {
      this.matchingUsers = this.users.filter(user =>
        user.roles.some(userRole => userRole.id === selectedRole.id) &&
        searchLower.every(word =>
          user.firstName.toLowerCase().includes(word) || user.lastName.toLowerCase().includes(word)
        )
      );

      this.nonMatchingUsers = this.users.filter(user =>
        !user.roles.some(userRole => userRole.id === selectedRole.id) &&
        searchLower.every(word =>
          user.firstName.toLowerCase().includes(word) || user.lastName.toLowerCase().includes(word)
        )
      );
    } else {
      this.filteredUsers = this.users.filter(user =>
        searchLower.every(word =>
          user.firstName.toLowerCase().includes(word) || user.lastName.toLowerCase().includes(word)
        )
      );
    }

    this.checkForOwners();
  }
  // Filter Roles
  filterRoleList() {
    const searchLower = this.searchStringRole.toLocaleLowerCase();
    this.filteredRoles = this.roles.filter((file) =>
      file.name.toLowerCase().includes(searchLower)
    )
  }
  // Filter Assigned Files
  filterAssignList() {
    const searchLower = this.searchStringAssign.toLocaleLowerCase();
    this.filteredAssignedFiles = this.assignedFileGroups.filter((file) =>
      file.name.toLowerCase().includes(searchLower))
  }
  // Filter Available Files
  filterAvailableList() {
    const searchLower = this.searchStringAvaliable.toLocaleLowerCase();
    this.filteredAvailableFiles = this.availableFileGroups.filter((file) =>
      file.name.toLowerCase().includes(searchLower))
  }
  //Enable + Disable File Groups ===========================================================================================
  toggleFileGroupSelection(fileGroup: FileGroup) {
    fileGroup.isSelected = fileGroup.isSelected != null ? !fileGroup.isSelected : true;
  }

  AssignSelectedFileGroupsToRole() {
    var selectedFileGroups = this.availableFileGroups.filter(fg => fg.isSelected != null && fg.isSelected == true)
    selectedFileGroups.forEach(fg => {
      fg.isSelected = false;
      this.availableFileGroups.splice(this.availableFileGroups.indexOf(fg), 1);
      this.assignedFileGroups.push(fg);
      this.selectedRole.permissions.fileGroups.groups.push(new FileGroupPermission(fg))
      this.addRoleToUpdatedCollection(this.selectedRole);
    });
    this.filteredAssignedFiles = this.assignedFileGroups
    this.filteredAvailableFiles = this.availableFileGroups;
  }

  RemoveSelectedFileGroupsFromRole() {
    var selectedFileGroups = this.assignedFileGroups.filter(fg => fg.isSelected != null && fg.isSelected == true)
    selectedFileGroups.forEach(fg => {
      fg.isSelected = false;
      this.assignedFileGroups.splice(this.assignedFileGroups.indexOf(fg), 1);
      this.availableFileGroups.push(fg);
      var item = this.selectedRole.permissions.fileGroups.groups.find(f => f.name == fg.id);
      var index = this.selectedRole.permissions.fileGroups.groups.indexOf(item);
      this.selectedRole.permissions.fileGroups.groups.splice(index, 1);
      this.addRoleToUpdatedCollection(this.selectedRole);
    });
    this.filteredAssignedFiles = this.assignedFileGroups
    this.filteredAvailableFiles = this.availableFileGroups;
  }
  async addRole() {
    var roleCount = this.roles.length;
    var newRoleName = `Role ${roleCount}`;
    var newRole = await this.adminService.createNewRole(newRoleName);

    this.roles.push(newRole);
    this.filteredRoles = this.roles;
  }

  async editRole() {
    this.enableEditing = !this.enableEditing;
    if (this.enableEditing === true) {
      this.editIndex = this.selectedRole.id;
    } else {
      this.enableEditing = false;
      this.editIndex = null;
    }
  }

  toggleEditing(role) {
    if (this.enableEditing === true) {
      this.editRoleNameEnable = true;
    }
  }

  updateEdit() {
    this.editIndex = null;
    this.editRoleNameEnable = false
    this.enableEditing = false;
    this.addRoleToUpdatedCollection(this.selectedRole);
  }

  checkForOwners() {
    var listOfOwners = [];
    this.filteredUsers.forEach(user => user.roles.forEach(role => {
      if (role.id === "_Owner") {
        listOfOwners.push(role)
      }
    }));
    if (listOfOwners.length < 2) {
      this.oneOwner = true;
    } else {
      this.oneOwner = false;
    }
  }
  isOnlyOneOwner(): boolean {
    let ownerCount = 0;
    for (let user of this.users) {
      if (user.roles.some(role => role.id === '_Owner')) {
        ownerCount++;
      }
    }
    return ownerCount <= 1;
  }
  private calculateEnabledRoles() {
    this.roles.forEach(r => r.enable = false);
    this.selectedUser.roles.forEach(userRole => {
      this.roles.forEach(r => {
        if (r.id === userRole.id) {
          r.enable = true;
          r.expirationDate = userRole.expirationDate;
        }
      });
    });
  }


  //Query if permissions are enabled or disabled ===========================================================================================
  public tablePermissionEnabled(tableId: string, permissionName: string): boolean {
    var enabled = false;
    var tablePermission = this.selectedRole.permissions.tables.dataSets.find(t => t.name === tableId);
    if (tablePermission && tablePermission.permission.split(",").some(p => p.includes(permissionName))) {
      enabled = true;
    }
    if (this.selectedRole.permissions.securityAdmin || this.selectedRole.permissions.tables.admin) {
      enabled = true;
    }
    return enabled;
  }

  public tableAdminEnabled(): boolean {
    return (this.selectedRole.permissions.securityAdmin || this.selectedRole.permissions.tables.admin)
  }

  public integrationPermissionEnabled(integrationId: string, permissionName: string) {
    var enabled = false;

    var integrationPermission = this.selectedRole.permissions.integrationPermissions.integrations.find(i => i.name === integrationId)

    if (integrationPermission && integrationPermission.permission.split(",").some(p => p.includes(permissionName))) {
      return enabled = true;
    }
    if (this.selectedRole.permissions.securityAdmin || this.selectedRole.permissions.integrationPermissions.admin) {

      return enabled = true;
    }
    return enabled;
  }

  public integrationAdminEnabled() {
    return (this.selectedRole.permissions.securityAdmin || this.selectedRole.permissions.integrationPermissions.admin)
  }


  //Enable + Disable Permissions ===========================================================================================
  public toggleTablePermission(tableId: string, permissionName: string) {
    var tablePermission = this.selectedRole.permissions.tables.dataSets.find(t => t.name === tableId);
    if (!tablePermission) {
      this.selectedRole.permissions.tables.dataSets.push({ name: tableId, permission: `${permissionName}` });
    } else {
      var permissionsArray = tablePermission.permission.split(",");
      for (let i = 0; i < permissionsArray.length; i++) {
        permissionsArray[i] = permissionsArray[i].trim();
      }
      if (permissionsArray.some(i => i === 'None')) {
        permissionsArray.splice(permissionsArray.indexOf("None"), 1);
      }
      if (permissionsArray.some(p => p === permissionName)) {
        permissionsArray.splice(permissionsArray.indexOf(permissionName), 1);
      }
      else {
        permissionsArray.push(permissionName);
      }
      tablePermission.permission = permissionsArray.join(",")
      if (tablePermission.permission.length === 0) {
        tablePermission.permission = "None"
      }
    }
    this.addRoleToUpdatedCollection(this.selectedRole);
  }

  public toggleIntegrationPermission(integrationId: string, permissionName: string) {
    var integrationPermission = this.selectedRole.permissions.integrationPermissions.integrations.find(i => i.name === integrationId)
    if (!integrationPermission) {
      this.selectedRole.permissions.integrationPermissions.integrations.push({ name: integrationId, permission: `${permissionName}` });
    } else {
      var permissionsArray = integrationPermission.permission.split(",");

      for (let i = 0; i < permissionsArray.length; i++) {
        permissionsArray[i] = permissionsArray[i].trim();
      }
      if (permissionsArray.some(i => i === 'None')) {
        permissionsArray.splice(permissionsArray.indexOf("None"), 1);
      }

      if (permissionsArray.some(i => i.includes(permissionName))) {
        let h = permissionsArray.indexOf(permissionName)
        permissionsArray.splice(h, 1);
      }
      else {
        permissionsArray.push(permissionName);
      }

      integrationPermission.permission = permissionsArray.join(",");
      if (integrationPermission.permission.length === 0) {
        integrationPermission.permission = "None"
      }
    }
    this.addRoleToUpdatedCollection(this.selectedRole);
  }

  public toggleTableAdmin() {
    this.selectedRole.permissions.tables.admin = !this.selectedRole.permissions.tables.admin;
    if (this.selectedRole.permissions.tables.admin === true) {
      this.selectedRole.permissions.tables.dataSets.forEach(table => {
        table.permission += (", Admin");
      })
    } else {
      this.selectedRole.permissions.tables.dataSets.forEach(table => {
        let removeAdmin = table.permission.replace(", Admin", '')
        table.permission = removeAdmin;
      })
    }
    this.addRoleToUpdatedCollection(this.selectedRole);
  }

  public toggleSecurityAdmin() {
    this.selectedRole.permissions.securityAdmin = !this.selectedRole.permissions.securityAdmin;
    this.addRoleToUpdatedCollection(this.selectedRole);
  }

  public toggleIntegrationAdmin() {
    this.selectedRole.permissions.integrationPermissions.admin = !this.selectedRole.permissions.integrationPermissions.admin;
    if (this.selectedRole.permissions.integrationPermissions.admin === true) {
      this.selectedRole.permissions.integrationPermissions.integrations.forEach(integration => {
        integration.permission += (", Admin");
      })
    } else {
      this.selectedRole.permissions.integrationPermissions.integrations.forEach(integration => {
        let removeAdmin = integration.permission.replace(", Admin", '')
        integration.permission = removeAdmin;
      })
    }
    this.addRoleToUpdatedCollection(this.selectedRole);
  }


  //Track Changes for Save Functionality ===========================================================================================
  public addUserToUpdatedCollection(user: UserV2) {
    this.showSaveButton = true;
    if (!this.updatedUsers.some(u => u.id == user.id)) {
      this.updatedUsers.push(user);
    }
  }
  public initialRoles: Role[] = JSON.parse(JSON.stringify(this.roles));

  public addRoleToUpdatedCollection(role: Role) {
    if (!this.updatedRoles.some(r => r.id == role.id)) {
      this.updatedRoles.push(role);
          this.showSaveButton = true;
      }
  }

  async save() {
    await this.adminService.saveUpdatedRoles(this.updatedRoles);
    await this.adminService.saveUpdatedUsers(this.updatedUsers);
    this.toastService.showSuccessToast('', 'Saving Complete!');
    this.showSaveButton = false;
  }

  //Helper methods ===========================================================================================
  private toTablePermission(tableData: TableData): TablePermission {
    let t = new TablePermission();
    t.name = tableData.id;
    t.actualName = tableData.name;
    return t;
  }

  toggleLocalhost() {
    this.selectedRole.permissions.disLocalhostPermission = !this.selectedRole.permissions.disLocalhostPermission;
    this.showSaveButton = true;
    this.addRoleToUpdatedCollection(this.selectedRole);
  }

  //gray-outlist-list
  shouldGrayBackground(index: number): boolean {
    return index % 2 === 1;
  }
}
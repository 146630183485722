import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor} from '@angular/common/http';
import { Injectable,} from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheInterceptorService implements HttpInterceptor {
    constructor() {}

    // This header prevents the browser from using cached data
    intercept(req: HttpRequest<any>, next: HttpHandler) {
      const httpRequest = req.clone({
        headers: req.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      })
  
      return next.handle(httpRequest)
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConfiguration } from '../models/app-configuration';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configration: AppConfiguration;
  public $configuration: BehaviorSubject<AppConfiguration>; 

  constructor(private http: HttpClient) {
    this.$configuration = new BehaviorSubject<AppConfiguration>(null);
    this.loadConfiguration();
  }

  private loadConfiguration() {
    this.http.get<AppConfiguration>('assets/config.json').subscribe(data => {
      data.environment = data.environment.toUpperCase();
      this.configration = data;
      this.$configuration.next(this.configration);
    });
  }

  getEnvironment(){
    return this.$configuration.value.environment;
  }
}
